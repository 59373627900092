import { DetailsList, IColumn } from "@fluentui/react";

interface Report {
    key: number;
    customerName: string;
    reportDate: Date;
  }
  
  const columns: IColumn[] = [
    { key: "name", name: "Customer Name", fieldName: "customerName", minWidth: 100, maxWidth: 350 },
    { key: "reportDate", name: "Report Date", fieldName: "reportDate", minWidth: 100 },
  ];
  
  const items: Report[] = [
    { key: 1, customerName: "John Smith", reportDate: new Date('3/1/2023') },
    { key: 2, customerName: "Jane Doe", reportDate: new Date() },
    { key: 3, customerName: "Bob Johnson", reportDate: new Date() },
    { key: 4, customerName: "Samantha Lee", reportDate: new Date() },
    { key: 5, customerName: "Michael Chen", reportDate: new Date() },
  ];

function ViewReports() {
    return ( <div> <DetailsList
        columns={columns}
        items={items}
        selectionMode={0}
        compact={true}
        checkboxVisibility={2}
      /></div> );
}

export default ViewReports;