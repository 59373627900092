import React from "react";
import { DetailsList, IColumn } from "@fluentui/react";

interface Grade {
  key: number;
  name: string;
  customer: string;
}

const columns: IColumn[] = [
  { key: "name", name: "Grade Name", fieldName: "name", minWidth: 100, maxWidth: 350 },
  { key: "customer", name: "Customer", fieldName: "customer", minWidth: 100 },
];

interface GradeListProps {
  onGradeClick: (item: any) => void;
}



function GradeList(props: GradeListProps) {
  const { onGradeClick } = props;
  const items: Grade[] = [
    { key: 1, name: "Grade 1", customer: "John Smith" },
    { key: 2, name: "Grade 2", customer: "Jane Doe" },
    { key: 3, name: "Grade 3", customer: "Bob Johnson" },
    { key: 4, name: "Grade 4", customer: "Bob Johnson" },
    { key: 5, name: "Grade 5", customer: "Michael Chen" },
  ];

  const handleItemInvoked = (item: any) => {
    onGradeClick(item);
  };

  return (
    <DetailsList
      columns={columns}
      items={items}
      selectionMode={0}
      compact={true}
      checkboxVisibility={2}
      onItemInvoked={handleItemInvoked}
    />
  );
}

export default GradeList;