import React from "react";
import { DefaultButton, DetailsList, IColumn } from "@fluentui/react";

interface ReportsListProps {
    onReportClick: (item: any) => void;
  }

  interface Analysis{
    key: number;
    customer: string;
    report: string;
    date: Date;
  }
  
  const columns: IColumn[] = [
    { key: "customer", name: "Customer", fieldName: "customer", minWidth: 200,maxWidth: 350 },
    { key: "report", name: "Report Name", fieldName: "report", minWidth: 100, maxWidth: 350 },
    { key: "date", name: "Report Date", fieldName: "date", minWidth: 100, maxWidth: 200 },
    {
      key: "generatePdf",
      name: "",
      minWidth: 50,
      maxWidth: 50,
      onRender: (item: Analysis) => (
        <DefaultButton
          text="Download PDF"
          onClick={() => alert("Report Generated")}
        />
      ),
    },
  ];


function ReportsList(props: ReportsListProps) {
    const today = new Date();

    const { onReportClick } = props;
    const items: Analysis[] = [
      { key: 1, report: "report 1", customer: "John Smith",date: new Date(today.setDate(today.getDate() - 31))  },
      { key: 2, report: "report 2", customer: "Jane Doe, John Doe, A Happy Camper", date: new Date(today.setDate(today.getDate() - 45)) },
      { key: 3, report: "report 3", customer: "Bob Johnson", date: new Date(today.setDate(today.getDate() - 98)) },
      { key: 4, report: "report 4", customer: "Bob Johnson", date: new Date(today.setDate(today.getDate() - 21)) },
      { key: 5, report: "report 5", customer: "Michael Chen", date: new Date(today.setDate(today.getDate() - 14)) },
    ];
  
    const formattedItems = items.map((item) => ({
      ...item,
      date: item.date.toLocaleDateString(),
    }));
  
    const handleItemInvoked = (item: any) => {
      onReportClick(item);
    };
  
    return (
      <DetailsList
        columns={columns}
        items={formattedItems}
        selectionMode={0}
        compact={true}
        checkboxVisibility={2}
        onItemInvoked={handleItemInvoked}
      />
    );
}

export default ReportsList;