import React from "react";
import { DefaultButton, DetailsList, IColumn } from "@fluentui/react";

interface AnalysisListProps {
    onGradeClick: (item: any) => void;
  }

  interface Analysis{
    key: number;
    customer: string;
    grade: string;
    date: Date;
  }
  
  const columns: IColumn[] = [
    { key: "customer", name: "Customer", fieldName: "customer", minWidth: 200,maxWidth: 350 },
    { key: "grade", name: "Grade Name", fieldName: "grade", minWidth: 100, maxWidth: 350 },
    { key: "date", name: "Analysis Date", fieldName: "date", minWidth: 100, maxWidth: 200 },
    {
      key: "generatePdf",
      name: "",
      minWidth: 50,
      maxWidth: 50,
      onRender: (item: Analysis) => (
        <DefaultButton
          text="Generate Certificate of Analysis PDF"
          onClick={() => alert("Report Generated")}
        />
      ),
    },
  ];

function AnalysisList(props: AnalysisListProps) {
  const today = new Date();

  const { onGradeClick } = props;
  const items: Analysis[] = [
    { key: 1, grade: "Grade 1", customer: "John Smith",date: new Date(today.setDate(today.getDate() - 31))  },
    { key: 2, grade: "Grade 2", customer: "Jane Doe, John Doe, A Happy Camper", date: new Date(today.setDate(today.getDate() - 45)) },
    { key: 3, grade: "Grade 3", customer: "Bob Johnson", date: new Date(today.setDate(today.getDate() - 98)) },
    { key: 4, grade: "Grade 4", customer: "Bob Johnson", date: new Date(today.setDate(today.getDate() - 21)) },
    { key: 5, grade: "Grade 5", customer: "Michael Chen", date: new Date(today.setDate(today.getDate() - 14)) },
  ];

  const formattedItems = items.map((item) => ({
    ...item,
    date: item.date.toLocaleDateString(),
  }));

  const handleItemInvoked = (item: any) => {
    onGradeClick(item);
  };

  return (
    <DetailsList
      columns={columns}
      items={formattedItems}
      selectionMode={0}
      compact={true}
      checkboxVisibility={2}
      onItemInvoked={handleItemInvoked}
    />
  );
}

export default AnalysisList;