import {
  DefaultButton,
  DetailsList,
  Dropdown,
  IColumn,
  IDropdownStyles,
  Label,
  PrimaryButton,
  TextField,
} from "@fluentui/react";
import { useState } from "react";

interface GradeFormProps {
  onClose: () => void;
  selectedGrade: any;
  showSieveTest: boolean;
}

const DropdownOptions = [
  { key: "John Smith", text: "John Smith" },
  { key: "Jane Doe", text: "Jane Doe" },
  { key: "Bob Johnson", text: "Bob Johnson" },
  { key: "Samantha Lee", text: "Samantha Lee" },
  { key: "Michael Chen", text: "Michael Chen" },
];

const dropdownStyles: Partial<IDropdownStyles> = { dropdown: { width: 300 } };

const buttonStyles = {
  root: {
    margin: "10px",
    padding: "10px",
  },
};

interface IFormData {
  key: string;
  testName: string;
  minValue: string;
  maxValue: string;
}

interface ISieveData {
  key: string;
  sieve: string;
  MAXIMUMSPEC: string;
  MINIMUMSPEC: string;
}

const defaultFormData: IFormData[] = [
  { key: "1", testName: "MOISTURE", minValue: "0", maxValue: "10" },
  { key: "2", testName: "PH", minValue: "20", maxValue: "30" },
  { key: "3", testName: "BASE PERMEABILITY", minValue: "40", maxValue: "50" },
  { key: "4", testName: "GRAIN FINENESS", minValue: "40", maxValue: "50" },
  { key: "5", testName: "ADV", minValue: "40", maxValue: "50" },
  { key: "6", testName: "LOI", minValue: "40", maxValue: "50" },
  { key: "7", testName: "TEMPERATURE", minValue: "40", maxValue: "50" },
  { key: "8", testName: "TURBIDITY ", minValue: "40", maxValue: "50" },
];

const defaultSieveData: ISieveData[] = [
  { key: "1", sieve: "20", MAXIMUMSPEC: "1", MINIMUMSPEC: "2" },
  { key: "2", sieve: "30", MAXIMUMSPEC: "1", MINIMUMSPEC: "2" },
  { key: "3", sieve: "40", MAXIMUMSPEC: "1", MINIMUMSPEC: "2" },
  { key: "4", sieve: "50", MAXIMUMSPEC: "1", MINIMUMSPEC: "2" },
  { key: "5", sieve: "70", MAXIMUMSPEC: "1", MINIMUMSPEC: "2" },
  { key: "6", sieve: "100", MAXIMUMSPEC: "1", MINIMUMSPEC: "2" },
  { key: "7", sieve: "140", MAXIMUMSPEC: "1", MINIMUMSPEC: "2" },
  { key: "8", sieve: "200", MAXIMUMSPEC: "1", MINIMUMSPEC: "2" },
  { key: "9", sieve: "270", MAXIMUMSPEC: "1", MINIMUMSPEC: "2" },
  { key: "10", sieve: "PAN", MAXIMUMSPEC: "1", MINIMUMSPEC: "2" },
];

function GradeForm(props: GradeFormProps) {
  const [formData, setFormData] = useState<IFormData[]>(defaultFormData);
  const [sieveData] = useState<ISieveData[]>(defaultSieveData);
  const [showSieveTest, setShowSieveTest] = useState(false);

  const columns: IColumn[] = [
    {
      key: "testName",
      name: "Test Name",
      fieldName: "testName",
      minWidth: 100,
      maxWidth: 200,
      isResizable: true,
      onRender: (item: IFormData) => (
        <input
          value={item.testName}
          onChange={(e) =>
            handleFormDataChange(item, "testName", e.target.value)
          }
        />
      ),
    },
    {
      key: "minValue",
      name: "Min Value",
      fieldName: "minValue",
      minWidth: 100,
      maxWidth: 200,
      isResizable: true,
      onRender: (item: IFormData) => (
        <input
          value={item.minValue}
          onChange={(e) =>
            handleFormDataChange(item, "minValue", e.target.value)
          }
        />
      ),
    },
    {
      key: "maxValue",
      name: "Max Value",
      fieldName: "maxValue",
      minWidth: 100,
      maxWidth: 200,
      isResizable: true,
      onRender: (item: IFormData) => (
        <input
          value={item.maxValue}
          onChange={(e) =>
            handleFormDataChange(item, "maxValue", e.target.value)
          }
        />
      ),
    },
  ];

  const sieveColumns: IColumn[] = [
    {
      key: "1",
      name: "SIEVE",
      fieldName: "sieve",
      minWidth: 50,
      maxWidth: 100,
      isResizable: false,
    },
    {
      key: "2",
      name: "MAXIMUM SPEC",
      fieldName: "MAXIMUMSPEC",
      minWidth: 100,
      maxWidth: 200,
      isResizable: true,
    },
    {
      key: "3",
      name: "MINIMUM SPEC",
      fieldName: "MINIMUMSPEC",
      minWidth: 100,
      maxWidth: 200,
      isResizable: true,
    },
  ];

  const handleAddSieve = () => {
    setShowSieveTest(true);
  };

  const handleAddCamsizer = () => {
    setShowSieveTest(false);
  };

  const handleSubmit = (event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault();
    // Submit form data
    props.onClose(); // Close the form
  };

  const handleAddRowClick = () => {
    const newKey = (parseInt(formData[formData.length - 1].key) + 1).toString();
    setFormData((prevState) => [
      ...prevState,
      { key: newKey, testName: "", minValue: "", maxValue: "" },
    ]);
  };

  const handleFormDataChange = (
    item: IFormData,
    fieldName: keyof IFormData,
    newValue: string
  ) => {
    setFormData((prevState) => {
      const updatedFormData = prevState.map((dataItem) => {
        if (dataItem.key === item.key) {
          return { ...dataItem, [fieldName]: newValue };
        }
        return dataItem;
      });
      return updatedFormData;
    });
  };

  return (
    <div>
      {props.showSieveTest === true ? (
        <h2>Edit Grade</h2>
      ) : (
        <h2>Create New Grade</h2>
      )}
      <form onSubmit={handleSubmit}>
        <div>
          <Label htmlFor="gradeName">Grade Name:</Label>
          <TextField type="text" id="gradeName" name="gradeName" />
        </div>
        <div>
          <Dropdown
            placeholder="Select customer(s)"
            label="Customers"
            //selectedKeys={selectedKeys}
            // eslint-disable-next-line react/jsx-no-bind
            //onChange={onChange}
            multiSelect
            options={DropdownOptions}
            styles={dropdownStyles}
          />
          <DetailsList items={formData} columns={columns} />
          <div>
            <PrimaryButton onClick={handleAddRowClick} styles={buttonStyles}>
              Add Row
            </PrimaryButton>
            <DefaultButton styles={buttonStyles} onClick={handleAddSieve}>Add SIEVE</DefaultButton>
            <DefaultButton styles={buttonStyles} onClick={handleAddCamsizer}>Add CAMSIZER</DefaultButton>
          </div>
        </div>
        {showSieveTest && (
        <div>
          <br />
          <Label>This sieve form will be editable to add spec upon approval of UI</Label>
          <DetailsList items={sieveData} columns={sieveColumns} />
        </div>
      )}
        <br />
        <PrimaryButton type="submit" styles={buttonStyles}>
          Save
        </PrimaryButton>
        <DefaultButton onClick={props.onClose} styles={buttonStyles}>
          Cancel
        </DefaultButton>
      </form>
      
    </div>
  );
}

export default GradeForm;
