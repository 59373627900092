import { DefaultButton, PrimaryButton } from "@fluentui/react";
import { useState } from "react";
import NewReport from "./NewReport";
import ReportsList from "./ReportsList";
import SieveAnalysis from "./SieveAnalysis";
import ViewReports from "./ViewReports";

const buttonStyles = {
  root: {
    margin: "10px",
    padding: "10px",
  },
};

function Reports() {
    const [showForm, setShowForm] = useState(false);
    const [showSieve, setShowSieve] = useState(false);
    const [showReports, setShowReports] = useState(false);

    const handleCloseForm = () => {
      setShowForm(false);
    };

  return (
    <div>
      <PrimaryButton styles={buttonStyles} onClick={() => {setShowForm(true);setShowSieve(false);setShowReports(false)}}>New Report</PrimaryButton>      
      <DefaultButton styles={buttonStyles} onClick={() => {setShowForm(false);setShowSieve(true);setShowReports(false)}}>View Sieve Analysis</DefaultButton>
      <DefaultButton styles={buttonStyles}>
        View Camsizer Analysis
      </DefaultButton>
      <div>
        {showForm && <NewReport onClose={handleCloseForm}/>}
        {showSieve && <SieveAnalysis />}
       {showReports && <ViewReports />}
       {!showForm && <ReportsList onReportClick={function (item: any): void {
        throw new Error("Function not implemented.");
      } } />}
      </div>
      
    </div>
  );
}

export default Reports;
