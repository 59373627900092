import { PrimaryButton } from "@fluentui/react";
import { useState } from "react";
import GradeForm from "./GradeForm";
import GradeList from "./GradeList";

function Grades() {
    const [showForm, setShowForm] = useState(false);
    const [selectedGrade, setSelectedGrade] = useState(null);
    const [showSieveTest, setShowSieveTest] = useState(false);

    const handleCreateGradeClick = () => {
      setShowForm(true);
      setSelectedGrade(null);
      setShowSieveTest(false);
    };

    const handleCloseForm = () => {
        setShowForm(false);
        setSelectedGrade(null);
      };

      const handleGradeClick = (item:any) => {
        setSelectedGrade(item);
        setShowForm(true);
        setShowSieveTest(true);
      };

    return ( <div>{!showForm && <div><h1>Grades</h1><PrimaryButton onClick={handleCreateGradeClick}>Create New Grade</PrimaryButton></div>}
    {showForm && <GradeForm onClose={handleCloseForm} selectedGrade={selectedGrade}  showSieveTest={showSieveTest}/>}
    {!showForm &&  <GradeList onGradeClick={handleGradeClick} />}</div> );
}

export default Grades;