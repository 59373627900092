
function Dashboard() {
  

    return ( <div><h2>The Smarter Choice</h2>
        Smart Sand, Inc is a fully integrated sand supply and services company, offering complete sand, logistics, storage and management solutions to our customers with our in-basin transloading terminals and our SmartSystem™ storage capabilities. We own and operate premium sand mines and related processing facilities in Wisconsin and Illinois.
        
        Northern White Sand is the first and best choice of proppant for hydraulic fracturing as well as, vital industrial markets throughout North America, such as Glass, Foundry, Building Products, Filtration, Geothermal, Renewables, Ceramics, Turf & Landscape, Retail, Recreation and more. The story extends beyond sand itself; sustainable supply, massive reserve, quality focus, environmental sensitivity and efficient logistics through unit train delivery capability is smarter.
        
        At Smart Sand, we think it is smarter to look at the big picture: moving sand from the origin to destinations reliably and continuously. Smart Sand is invested in sustainability, in our community and in the environment. We are a proud member of the Wisconsin Industrial Sand Association and the Wisconsin’s marquee environmental performance partnership, Green Tier.
        
        Combining state-of-the-art mining, logistics, scientific and IT technologies with a commitment to sustainability makes Smart Sand the logical and intelligent choice for premium monocrystalline sand.
        
       <h2> A Vision for The Future</h2>

       Sieve analysis is a widely used method to determine the particle size distribution of sand and other granular materials.

        In a sieve stack test, a sample of sand is placed on the top sieve of a stack of sieves, which are stacked from largest to smallest mesh size. The stack is then shaken for a set period of time, and the sand particles are sorted by size as they pass through the different sieves.

        The weight of sand retained on each sieve is measured, and this data is used to calculate the sand's particle size distribution. This information can be used to evaluate the quality of the sand and to ensure that it meets the requirements of various industries and applications, such as construction, manufacturing, and oil and gas.

        Sieve analysis is a simple and effective way to determine the particle size distribution of sand, and it is a commonly used method in the mining industry.

        <br></br>
        <strong>Sample tracking:</strong> The lab app should allow the lab to keep track of each sample of sand being tested, including its source, date received, and any other relevant information.
        <br></br>
        <strong>Test parameters:</strong> The lab app should allow the user to input test parameters such as the number of sieves in the stack, the size of each sieve, and the duration of shaking.
        <br></br>
        <strong>Results recording:</strong> The lab app should allow the user to record the weight of sand retained on each sieve, and should automatically calculate the particle size distribution of the sand based on this data.
        <br></br>
        <strong>Data visualization:</strong> The lab app should provide graphical representations of the particle size distribution data, such as histograms or cumulative distribution curves, to make it easier to interpret and communicate the results.
        <br></br>
        <strong>Report generation:</strong> The lab app should allow the user to generate reports summarizing the test results, including sample information, test parameters, and particle size distribution data.
        <br></br>
        <strong>Data export:</strong> The lab app should allow the user to export the test results in a variety of formats, such as CSV or Excel, to facilitate data analysis and sharing.
        <br></br>
        <strong>Customization:</strong> The lab app should allow the user to customize the test parameters and data visualization options to meet their specific needs.
        <br></br>
        <strong>User management:</strong> The lab app should allow the lab to manage user accounts, control access to the lab app's features and data, and ensure data security and confidentiality.
        <br></br>
        </div> );
}

export default Dashboard;