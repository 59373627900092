/* eslint-disable jsx-a11y/alt-text */
import React from "react";
import "./App.css";
import Navigation from "./Navigation";
import { BrowserRouter, Switch, Route } from "react-router-dom";
import Analysis from "./Analysis";
import Customers from "./Customers";
import Dashboard from "./Dashboard";
import Grades from "./Grades";
import Reports from "./Reports";
import Admin from "./Admin";
function App() {
  return (
    <BrowserRouter>
    <div className="grid-container">
      <div className="grid-item grid-item-header">
        <img src="https://smartsand.com/wp-content/uploads/2022/01/smartsand.jpg"/>
      </div>
      <div className="grid-item grid-item-sidebar">
        <Navigation />
      </div>
      <div className="grid-item grid-item-main">
        {/* Render the component for the current URL path */}
        <Switch>
          <Route exact path="/" component={Dashboard} />
          <Route path="/analysis" component={Analysis} />
          <Route path="/reports" component={Reports} />
          <Route path="/customers" component={Customers} />
          <Route path="/grades" component={Grades} />
          <Route path="/admin" component={Admin} />
        </Switch>
      </div>
      <div className="grid-item grid-item-footer">
      <p>© Copyright {new Date().getFullYear()} SmartSand, Inc.</p>
      </div>
    </div>
  </BrowserRouter>

  );
}

export default App;
