/* eslint-disable @typescript-eslint/no-unused-vars */
import { Checkbox, DatePicker, DayOfWeek, DefaultButton, IDropdownOption, Label, PrimaryButton, TextField } from "@fluentui/react";
import { useState } from "react";
import * as React from 'react';

interface ReportFormProps {
    onClose: () => void;
  }

  function _onChange(ev?: React.FormEvent<HTMLElement | HTMLInputElement>, isChecked?: boolean) {
    console.log(`The option has been changed to ${isChecked}.`);
  }

  const days: IDropdownOption[] = [
    { text: 'Sunday', key: DayOfWeek.Sunday },
    { text: 'Monday', key: DayOfWeek.Monday },
    { text: 'Tuesday', key: DayOfWeek.Tuesday },
    { text: 'Wednesday', key: DayOfWeek.Wednesday },
    { text: 'Thursday', key: DayOfWeek.Thursday },
    { text: 'Friday', key: DayOfWeek.Friday },
    { text: 'Saturday', key: DayOfWeek.Saturday },
  ];
  

function NewReport(props:ReportFormProps) {
    const handleSubmit = (event: React.FormEvent<HTMLFormElement>) => {
        event.preventDefault();
        // Submit form data
        props.onClose(); // Close the form
      };
      const [firstDayOfWeek, setFirstDayOfWeek] = useState(DayOfWeek.Sunday);


    return ( <div>
    <h2>Create New Report</h2>
    <form onSubmit={handleSubmit}>
    <DatePicker
        isRequired
        label="Start Date"
        placeholder="Select a date..."
        ariaLabel="Select a date"
        // DatePicker uses English strings by default. For localized apps, you must override this prop.
        //strings={defaultDatePickerStrings}
      />
          <DatePicker
        isRequired
        label="Start Date"
        placeholder="Select a date..."
        ariaLabel="Select a date"
        // DatePicker uses English strings by default. For localized apps, you must override this prop.
        //strings={defaultDatePickerStrings}
      />
    <Checkbox label="By Customer" onChange={_onChange} />
    <Checkbox label="By Grade Name" onChange={_onChange} />
    <Checkbox label="By BOL" onChange={_onChange} />
    <Checkbox label="By PO" onChange={_onChange} />
    <Checkbox label="Lab Tech Name" onChange={_onChange} />

      <div>
        <Label htmlFor="customerName">Customer Name:</Label>
        <TextField type="text" id="customerName" name="customerName" />
      </div>
      <h4>This info is populated when customer is selected....</h4>
      <div>
        <Label htmlFor="address1">Address 1:</Label>
        <TextField type="text" id="address1" name="address1" />
      </div>
      <div>
        <Label htmlFor="address2">Address 2:</Label>
        <TextField type="text" id="address2" name="address2" />
      </div>
      <div>
        <Label htmlFor="state">State:</Label>
        <TextField type="text" id="state" name="state" />
      </div>
      <div>
        <Label htmlFor="zipCode">Zip Code:</Label>
        <TextField type="text" id="zipCode" name="zipCode" />
      </div>
      <div>
        <Label htmlFor="billingAddress">Billing Address:</Label>
        <TextField type="text" id="billingAddress" name="billingAddress" />
      </div>
      <div>
        <Label htmlFor="shippingAddress">Shipping Address:</Label>
        <TextField type="text" id="shippingAddress" name="shippingAddress" />
      </div>
      <div>
        <Label htmlFor="contactInfo">Contact Info:</Label>
        <TextField type="text" id="contactInfo" name="contactInfo" />
      </div>
      <br/>
      <PrimaryButton type="submit">Save</PrimaryButton>
      <DefaultButton onClick={props.onClose}>Cancel</DefaultButton>
    </form>
  </div> );
}

export default NewReport;