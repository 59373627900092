import {
  DefaultButton,
  DetailsList,
  Dropdown,
  IColumn,
  IDropdownOption,
  IDropdownStyles,
  Label,
  PrimaryButton,
} from "@fluentui/react";
import { useState } from "react";

const dropdownStyles: Partial<IDropdownStyles> = { dropdown: { width: 300 } };

const buttonStyles = {
  root: {
    marginTop: "10px",
    marginBottom: "10px",
    marginRight: "10px",
    padding: "10px",
  },
};

interface AnalysisFormProps {
  onClose: () => void;
  selectedAnalysis: any;
}

interface IFormData {
  key: string;
  testName: string;
  minValue: string;
  maxValue: string;
}

interface ISieveData {
  key: string;
  sieve: string;
  MAXIMUMSPEC: string;
  MINIMUMSPEC: string;
}

const DropdownOptions = [
  { key: "John Smith", text: "John Smith" },
  { key: "Jane Doe", text: "Jane Doe" },
  { key: "Bob Johnson", text: "Bob Johnson" },
  { key: "Samantha Lee", text: "Samantha Lee" },
  { key: "Michael Chen", text: "Michael Chen" },
];

const dropdownOptionsGrades: IDropdownOption[] = [
  { key: "1", text: "Grade 1" },
  { key: "2", text: "Grade 2" },
  { key: "3", text: "Grade 3" },
  { key: "4", text: "Grade 4" },
  { key: "5", text: "Grade 5" },
];

const defaultFormData: IFormData[] = [
  { key: "1", testName: "MOISTURE", minValue: "0", maxValue: "10" },
  { key: "2", testName: "PH", minValue: "20", maxValue: "30" },
  { key: "3", testName: "BASE PERMEABILITY", minValue: "40", maxValue: "50" },
  { key: "4", testName: "GRAIN FINENESS", minValue: "40", maxValue: "50" },
  { key: "5", testName: "ADV", minValue: "40", maxValue: "50" },
  { key: "6", testName: "LOI", minValue: "40", maxValue: "50" },
  { key: "7", testName: "TEMPERATURE", minValue: "40", maxValue: "50" },
  { key: "8", testName: "TURBIDITY ", minValue: "40", maxValue: "50" },
];

const defaultSieveData: ISieveData[] = [
  { key: "1", sieve: "20", MAXIMUMSPEC: "1", MINIMUMSPEC: "2" },
  { key: "2", sieve: "30", MAXIMUMSPEC: "1", MINIMUMSPEC: "2" },
  { key: "3", sieve: "40", MAXIMUMSPEC: "1", MINIMUMSPEC: "2" },
  { key: "4", sieve: "50", MAXIMUMSPEC: "1", MINIMUMSPEC: "2" },
  { key: "5", sieve: "70", MAXIMUMSPEC: "1", MINIMUMSPEC: "2" },
  { key: "6", sieve: "100", MAXIMUMSPEC: "1", MINIMUMSPEC: "2" },
  { key: "7", sieve: "140", MAXIMUMSPEC: "1", MINIMUMSPEC: "2" },
  { key: "8", sieve: "200", MAXIMUMSPEC: "1", MINIMUMSPEC: "2" },
  { key: "9", sieve: "270", MAXIMUMSPEC: "1", MINIMUMSPEC: "2" },
  { key: "10", sieve: "PAN", MAXIMUMSPEC: "1", MINIMUMSPEC: "2" },
];

function AnalysisForm(props: AnalysisFormProps) {
  const [formData, setFormData] = useState<IFormData[]>(defaultFormData);
  const [sieveData] = useState<ISieveData[]>(defaultSieveData);
  const [selectedFile, setSelectedFile] = useState<File | null>(null);

  const handleFileUpload = (event: React.ChangeEvent<HTMLInputElement>) => {
    const files = event.target.files;
    if (files && files.length > 0) {
      setSelectedFile(files[0]);
    }
  };

  const columns: IColumn[] = [
    {
      key: "testName",
      name: "TEST NAME",
      fieldName: "testName",
      minWidth: 100,
      maxWidth: 200,
      isResizable: true,
    },
    {
      key: "testValue",
      name: "TEST VALUE",
      fieldName: "testValue",
      minWidth: 100,
      maxWidth: 200,
      isResizable: true,
      onRender: (item: IFormData) => (
        <input
          value={item.minValue}
          onChange={(e) =>
            handleFormDataChange(item, "minValue", e.target.value)
          }
        />
      ),
    },
    {
      key: "minValue",
      name: "MIN VALUE",
      fieldName: "minValue",
      minWidth: 100,
      maxWidth: 200,
      isResizable: true,
    },
    {
      key: "maxValue",
      name: "MAX VALUE",
      fieldName: "maxValue",
      minWidth: 100,
      maxWidth: 200,
      isResizable: true,
    },
  ];

  const sieveColumns: IColumn[] = [
    {
      key: "1",
      name: "SIEVE",
      fieldName: "sieve",
      minWidth: 50,
      maxWidth: 100,
      isResizable: false,
    },
    {
      key: "testValue",
      name: "% RETAINED",
      fieldName: "testValue",
      minWidth: 100,
      maxWidth: 200,
      isResizable: true,
      onRender: (item: IFormData) => (
        <input
          value={item.minValue}
          onChange={(e) =>
            handleFormDataChange(item, "minValue", e.target.value)
          }
        />
      ),
    },
    {
      key: "2",
      name: "MAXIMUM SPEC",
      fieldName: "MAXIMUMSPEC",
      minWidth: 100,
      maxWidth: 200,
      isResizable: true,
    },
    {
      key: "3",
      name: "MINIMUM SPEC",
      fieldName: "MINIMUMSPEC",
      minWidth: 100,
      maxWidth: 200,
      isResizable: true,
    },
  ];

  const handleFormDataChange = (
    item: IFormData,
    fieldName: keyof IFormData,
    newValue: string
  ) => {
    setFormData((prevState) => {
      const updatedFormData = prevState.map((dataItem) => {
        if (dataItem.key === item.key) {
          return { ...dataItem, [fieldName]: newValue };
        }
        return dataItem;
      });
      return updatedFormData;
    });
  };

  const handleSubmit = (event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault();
    // Submit form data
    props.onClose(); // Close the form
  };

  const handleClearFile = () => {
    setSelectedFile(null);
  };

  return (
    <div>
      Add Analysis
      <form onSubmit={handleSubmit}>
        <Dropdown
          placeholder="Select customer(s)"
          label="Customers"
          //selectedKeys={selectedKeys}
          // eslint-disable-next-line react/jsx-no-bind
          //onChange={onChange}
          multiSelect
          options={DropdownOptions}
          styles={dropdownStyles}
        />
        <Dropdown
          placeholder="Select Grade"
          label="Grade for analysis"
          //selectedKeys={selectedKeys}
          // eslint-disable-next-line react/jsx-no-bind
          //onChange={onChange}
          multiSelect
          options={dropdownOptionsGrades}
          styles={dropdownStyles}
          required={true}
        />
      </form>
      <div>
        <DetailsList items={formData} columns={columns} />
      </div>
      <div>
        *if grade has camsizer values this is shown, else the sieve test is
        shown
        <Label>File Upload:</Label>
        <input type="file" onChange={handleFileUpload} />
        {selectedFile && (
          <p>
            Selected file: {selectedFile.name}{" "}
            <button onClick={handleClearFile}>Clear</button>
          </p>
        )}
      </div>
      <div>
        <DetailsList items={sieveData} columns={sieveColumns} />
      </div>
      <br />
      <PrimaryButton type="submit" styles={buttonStyles}>
        Save
      </PrimaryButton>
      <DefaultButton styles={buttonStyles}>Cancel</DefaultButton>
    </div>
  );
}

export default AnalysisForm;
