import { PrimaryButton } from "@fluentui/react";
import { useState } from "react";
import AnalysisForm from "./AnalysisForm";
import AnalysisList from "./AnalysisList";

function Analysis() {
  const [showForm, setShowForm] = useState(false);
    const [selectedGrade, setSelectedGrade] = useState(null);

    const handleCreateGradeClick = () => {
      setShowForm(true);
      setSelectedGrade(null);
    };

    const handleCloseForm = () => {
        setShowForm(false);
        setSelectedGrade(null);
      };

      const handleGradeClick = (item:any) => {
        setSelectedGrade(item);
        setShowForm(true);
      };

    return ( <div>{!showForm && <div><h1>Analysis</h1><PrimaryButton onClick={handleCreateGradeClick}>Create New Analysis</PrimaryButton></div>}
    {showForm && <AnalysisForm onClose={handleCloseForm} selectedAnalysis={selectedGrade} />}
    {!showForm &&  <AnalysisList onGradeClick={handleGradeClick} />}</div> );
}
export default Analysis;