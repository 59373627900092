import { DefaultButton, Label, PrimaryButton, TextField } from "@fluentui/react";

interface CustomerFormProps {
    onClose: () => void;
  }

function CustomerForm(props:CustomerFormProps) {


    
    const handleSubmit  = async (event: React.FormEvent<HTMLFormElement>) => {
        event.preventDefault();

        // Extract form values
        const formData = new FormData(event.currentTarget);
        const data = {
          CustomerName: formData.get("CustomerName") as string,
          ModifiedDateTime: new Date().toISOString(),
          ModifiedBy: "Admin", //  to be changed to current user
          CreatedDateTime: new Date().toISOString(),
          CreatedBy: "Admin" //  to be changed to current user
        };

      debugger;
        const endpoint = `/data-api/rest/Customers/`;
        const response = await fetch(endpoint, {
          method: "POST",
          headers: { "Content-Type": "application/json" },
          body: JSON.stringify(data)
        });
        const result = await response.json();
        console.table(result.value); 

        props.onClose(); // Close the form
      };

  return (
    <div>
      <h2>Create New Customer</h2>
      <form onSubmit={handleSubmit}>
        <div>
          <Label htmlFor="CustomerName">Customer Name:</Label>
          <TextField type="text" id="CustomerName" name="CustomerName" />
        </div>
         <div>
          <Label htmlFor="address1">Address 1:</Label>
          <TextField type="text" id="address1" name="address1" />
        </div>
        <div>
          <Label htmlFor="address2">Address 2:</Label>
          <TextField type="text" id="address2" name="address2" />
        </div>
        <div>
          <Label htmlFor="state">State:</Label>
          <TextField type="text" id="state" name="state" />
        </div>
        <div>
          <Label htmlFor="zipCode">Zip Code:</Label>
          <TextField type="text" id="zipCode" name="zipCode" />
        </div>
        <div>
          <Label htmlFor="billingAddress">Billing Address:</Label>
          <TextField type="text" id="billingAddress" name="billingAddress" />
        </div>
        <div>
          <Label htmlFor="shippingAddress">Shipping Address:</Label>
          <TextField type="text" id="shippingAddress" name="shippingAddress" />
        </div>
        <div>
          <Label htmlFor="contactInfo">Contact Info:</Label>
          <TextField type="text" id="contactInfo" name="contactInfo" />
        </div> 
        <br/>
        <PrimaryButton type="submit">Save</PrimaryButton>
        <DefaultButton onClick={props.onClose}>Cancel</DefaultButton>
      </form>
    </div>
  );
}

export default CustomerForm;