import { PrimaryButton } from "@fluentui/react";
import { useState } from "react";
import CustomerForm from "./CustomerForm";
import CustomerList from "./CustomerList";
import "./App.css";

function Customers() {
  const [showForm, setShowForm] = useState(false);


  const handleCreateCustomerClick = () => {
    setShowForm(true);
  };

  const handleCloseForm = () => {
    setShowForm(false);
  };

  return (
    <div>
      <div className="button">
        <PrimaryButton onClick={handleCreateCustomerClick}>Create New Customer</PrimaryButton>
      </div>
      <div>
        {showForm && <CustomerForm onClose={handleCloseForm} />}
        {!showForm && <CustomerList />}

      </div></div>);
}

export default Customers;