/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from "react";
import { EditRegular, DeleteRegular } from "@fluentui/react-icons";
import {
  TableBody,
  TableCell,
  TableRow,
  Table,
  TableHeader,
  TableHeaderCell,
  TableCellLayout,
  Button,
  makeStyles,
  shorthands,
  Spinner,
} from "@fluentui/react-components";

interface Customer {
  CustomerID: number;
  CustomerName: string;
  ContactPhoneNumber: string;
  ContactEmailAddress: string;
  CreatedDateTime: string;
  CreatedBy: string;
  ModifiedDateTime: string;
  ModifiedBy: string;
}

const columns = [
  { columnKey: "CustomerName", label: "Customer Name" },
  { columnKey: "ContactPhoneNumber", label: "Contact Phone" },
  { columnKey: "ContactEmailAddress", label: "Contact Email" },
  { columnKey: "ModifiedDateTime", label: "Modified Date" },
  { columnKey: "ModifiedBy", label: "Modified By" },
];

const useStyles = makeStyles({
  spinnercontainer: {
    "> div": { ...shorthands.padding("20px") },
  },
}); 

// Helper function to format UTC date to 'M/D/YYYY' format
const formatDate = (utcDateStr: string | number | Date) => {
  const date = new Date(utcDateStr);
  const month = date.getMonth() + 1;
  const day = date.getDate();
  const year = date.getFullYear();

  return `${month}/${day}/${year}`;
};

function CustomersList() {
  const [customers, setCustomers] = useState<Customer[]>([]);
  const style = useStyles();
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    const fetchCustomers = async () => {
      try {
        const response = await fetch("/data-api/rest/Customers");
        const data = await response.json();
        setCustomers(data.value);
        setLoading(false);
        console.log("Customers data:", customers);
      } catch (error) {
        console.error("Error fetching customers data:", error);
        setLoading(false);
      }
    };

    fetchCustomers();
  },[]);

  const handleDelete = async (
    listName: string,
    itemId: number
  ): Promise<void> => {
    try {
      //await SharePointService.recycleItem(listName, itemId);
      //await fetchDataFromSharePoint();
      console.log("Deleted item with ID:", itemId);
    } catch (error: any) {
      console.error("Error deleting item:", error);
    }
  };

  const handleEditClick = (itemId: number): React.MouseEventHandler<HTMLButtonElement> => () => {
      // Show the Edit dialog when "Edit" button is clicked
      console.log("Edit item with ID:", itemId);
  };
  
  return (
    <>
    {loading ? ( 
        <div className={style.spinnercontainer}>
          <Spinner appearance="primary" label="Loading Customers..." />
        </div>
      ):(
      <Table arial-label="Default table">
        <TableHeader>
          <TableRow>
            {columns.map((column) => (
              <TableHeaderCell key={column.columnKey}>
                {column.label}
              </TableHeaderCell>
            ))}
          </TableRow>
        </TableHeader>
        <TableBody>
          {customers.map((customer) => (
           (
              <TableRow key={customer.CustomerID}>
                <TableCell>
                  <TableCellLayout>{customer.CustomerName}</TableCellLayout>
                </TableCell>
                <TableCell>
                  <TableCellLayout>{customer.ContactPhoneNumber}</TableCellLayout>
                </TableCell>
                <TableCell>
                  <TableCellLayout>{customer.ContactEmailAddress}</TableCellLayout>
                </TableCell>
                <TableCell>{formatDate(customer.ModifiedDateTime)}</TableCell>
                <TableCell>
                  <TableCellLayout>{customer.ModifiedBy}</TableCellLayout>
                </TableCell>
                <TableCell role="gridcell" tabIndex={0}>
                  <TableCellLayout>
                    <Button
                      icon={<EditRegular />}
                      onClick={handleEditClick(customer.CustomerID)}
                      aria-label="Edit"
                    />
                    <Button
                      icon={<DeleteRegular />}
                      onClick={() => handleDelete("Addresses", customer.CustomerID)}
                      aria-label="Delete"
                    />
                  </TableCellLayout>
                </TableCell>
              </TableRow>
            )
          ))}
        </TableBody>
      </Table>
      )}
    </>
  );
}

export default CustomersList;
