import React from "react";
import {
  Nav,
  initializeIcons,
  INavStyles,
  INavLinkGroup,
} from "@fluentui/react";
import { useLocation } from "react-router-dom";

 const navStyles: Partial<INavStyles> = {
  root: {
    width: 208,
    height: '300px',
    boxSizing: "border-box",
    border: "1px solid #eee",
    overflowY: "auto",
  },
};

const navLinkGroups: INavLinkGroup[] = [
  {
    links: [
      {
        name: "HOME",
        url: "/",
        key: "dashboard",
        iconProps: {
          iconName: "Home",
          styles: {
            root: {
              fontSize: 20,
              color: "#106ebe",
            },
          },
        },
      },
      {
        name: "ANALYSIS",
        url: "/analysis",
        key: "analysis",
        iconProps: {
          iconName: "Home",
          styles: {
            root: {
              fontSize: 20,
               color: "#106ebe",
            },
          },
        },
      },
      {
        name: "REPORTS",
        url: "/reports",
        key: "reports",
        iconProps: {
          iconName: "Home",
          styles: {
            root: {
              fontSize: 20,
               color: "#106ebe",
            },
          },
        },
      },
      {
        name: "CUSTOMERS",
        url: "/customers",
        key: "customers",
        iconProps: {
          iconName: "Home",
          styles: {
            root: {
              fontSize: 20,
               color: "#106ebe",
            },
          },
        },
      },
      {
        name: "GRADES",
        url: "/grades",
        key: "grades",
        iconProps: {
          iconName: "Home",
          styles: {
            root: {
              fontSize: 20,
               color: "#106ebe",
            },
          },
        },
      },
      {
        name: "ADMIN",
        url: "/admin",
        key: "admin",
        iconProps: {
          iconName: "Home",
          styles: {
            root: {
              fontSize: 20,
               color: "#106ebe",
            },
          },
        },
      },
    ],
  },
];

function Navigation() {
  initializeIcons();
  const location = useLocation();

  // Get the key of the selected item based on the current route
  let selectedKey = "";
  if (location.pathname === "/") {
    selectedKey = "dashboard";
  } else if (location.pathname === "/customers") {
    selectedKey = "customers";
  }else if (location.pathname === "/analysis") {
    selectedKey = "analysis";
  }else if (location.pathname === "/reports") {
    selectedKey = "reports";
  }else if (location.pathname === "/grades") {
    selectedKey = "grades";
  }else if (location.pathname === "/admin") {
    selectedKey = "admin";
  }
  console.log("selectedKey", selectedKey);
  return <Nav 
  groups={navLinkGroups}
  selectedKey={selectedKey}
  styles={navStyles}
  />;
}

export default Navigation;
